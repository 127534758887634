<template>
  <ul class="select-tab">
    <li
      v-for="option in newOptions"
      tabindex="0"
      :key="option.id"
      :id="option.id"
      :title="option.value"
      class="select-tab__option text-18-500"
      :class="getOptionClass(option.id)"
      @click.stop="selectOption"
      @keydown.space.prevent="selectOption"
    >
      <slot name="option" :option="option">
        {{ option.value }}
      </slot>
    </li>
  </ul>
</template>

<script lang="ts">
import type { PropType } from "vue";

import { computed, ref } from "vue";
import { BaseSelectOption } from "./BaseSelect.vue";

export default defineNuxtComponent({
  emits: ["select"],

  props: {
    options: {
      type: Array as PropType<BaseSelectOption[]>,
      required: true,
    },
    value: {
      type: String as PropType<string>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const selectedOption = computed({
      get() {
        return props.value;
      },
      set(val) {
        emit("select", val);
      },
    });

    const newOptions = computed(() => props.options);

    function selectOption(event) {
      const id = event.target?.id;
      selectedOption.value = id;
    }

    function getOptionClass(id: string) {
      return {
        "select-tab__option_selected": id === selectedOption.value,
      };
    }

    return {
      newOptions,
      selectOption,
      getOptionClass,
    };
  },
});
</script>
